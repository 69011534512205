import React, { Component } from "react";
import LoginTab from './LoginForm';
 
class Login extends Component { 
  render() {
    return (
      <div>
        
        <p><LoginTab></LoginTab></p>
       
      </div>
      
    ); 
  }
}
 
export default Login;
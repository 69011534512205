import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import "./index.css";

//import syles 
import "./vendor/css/bootstrap.min.css"
import "./vendor/css/open-iconic-bootstrap.css"
import "./vendor/css/select2.min.css"


ReactDOM.render(
  <Main />,
  document.getElementById('root')
);
